import { SortDirection as TSortDirection } from '@angular/material/sort';
import { Division, MonitorDetailsLabel, MonitorDetailsTypes } from 'assets/constants/enums';
import { Branch } from '../../assets/models/Branch';

export enum DashboardSection {
	Active = 'active',
	Scheduled = 'scheduled',
	Hold = 'hold',
	ServiceQ = 'service_q',
	Cancelled = 'cancelled',
	Finished = 'finished',
	Audit = 'audit',
	LowScoreSurveys = 'low-score-surveys',
}

/** Default section that the user is redirected to when no route param is provided. */
export const DefaultSection = DashboardSection.Active;

export const DashboardSectionTitle = new Map<DashboardSection, string>([
	[DashboardSection.Active, 'Active'],
	[DashboardSection.Scheduled, 'Scheduled'],
	[DashboardSection.Hold, 'Hold'],
	[DashboardSection.ServiceQ, 'Service Q'],
	[DashboardSection.Cancelled, 'Cancelled'],
	[DashboardSection.Finished, 'Finished'],
	[DashboardSection.Audit, 'Audit'],
	[DashboardSection.LowScoreSurveys, 'Low Score Surveys'],
]);

export const DashboardSectionCompat = new Map<DashboardSection, string>([
	[DashboardSection.Active, 'Active'],
	[DashboardSection.Hold, 'Hold'],
	[DashboardSection.Scheduled, 'Scheduled Services'],
	[DashboardSection.ServiceQ, 'HoldInspection'],
	[DashboardSection.Cancelled, 'Cancelled'],
	[DashboardSection.Finished, 'Finished'],
	[DashboardSection.Audit, 'Audit'],
]);

export const POLLING_INTERVAL = 30000;

export const SortDirection: { [key: string]: TSortDirection } = {
	ASCENDING: 'asc',
	DESCENDING: 'desc',
	NO_SORT: '',
} as const;

const warnColor: Partial<CSSStyleDeclaration> = {
	backgroundColor: '#bf0711',
	borderColor: '#bf0711',
};
export const tripStatusStylesMap = new Map<string, Partial<CSSStyleDeclaration>>([
	[
		'new',
		{
			backgroundColor: '#454545',
			borderColor: '#454545',
		},
	],
	[
		'accepted',
		{
			backgroundColor: '#108043',
			borderColor: '#108043',
		},
	],
	[
		'on_route',
		{
			backgroundColor: '#50b2b9',
			borderColor: '#50b2b9',
		},
	],
	[
		'arrived',
		{
			backgroundColor: '#d79c02',
			borderColor: '#d79c02',
		},
	],
	[
		'towed',
		{
			backgroundColor: '#6e5ca8',
			borderColor: '#6e5ca8',
		},
	],
	[
		'finished',
		{
			backgroundColor: '#009ce4',
			borderColor: '#009ce4',
		},
	],
	[
		'pending_audit',
		{
			backgroundColor: '#6e5ca8',
			borderColor: '#6e5ca8',
		},
	],
	['cancelled', warnColor],
	['cancelled_by_driver', warnColor],
	['hasNotAccepted', warnColor],
	['isNotOnRoute', warnColor],
	['delayed', warnColor],
	['hasSurplusOrOndemand', warnColor],
	[
		'newMessage',
		{
			backgroundColor: '#f05124',
			borderColor: '#f05124',
			color: 'white',
		},
	],
	[
		'hasNotFinished',
		{
			backgroundColor: '#d79c02',
			borderColor: '#d79c02',
		},
	],
	[
		'onTime',
		{
			backgroundColor: '#454545',
			borderColor: '#454545',
		},
	],
]);

export const monitorStatusStylesMap = new Map<string, Partial<CSSStyleDeclaration>>([
	[MonitorDetailsTypes.HAS_NOT_ACCEPTED, warnColor],
	[MonitorDetailsTypes.IS_NOT_ON_ROUTE, warnColor],
	[MonitorDetailsTypes.HAS_NOT_FINISHED, { backgroundColor: '#D79C02', borderColor: '#D79C02' }],
	[MonitorDetailsTypes.ON_TIME, { backgroundColor: '#454545', borderColor: '#454545' }],
	[MonitorDetailsTypes.DELAYED, warnColor],
	[
		MonitorDetailsTypes.NEW_MESSAGE,
		{
			backgroundColor: '#F05124',
			borderColor: '#F05124',
			color: 'white',
		},
	],
	[MonitorDetailsTypes.REVIEW, { backgroundColor: '#58595b', color: 'white' }],
	[MonitorDetailsTypes.CHECK_IN, { backgroundColor: '#FFAA00', color: 'white' }],
	[MonitorDetailsTypes.QUALITAS_CO_CLOSURE_PENDING, { backgroundColor: '#FFB000', color: 'white' }],
]);

export const monitorDetailsMap = new Map([
	[MonitorDetailsTypes.HAS_NOT_ACCEPTED, MonitorDetailsLabel.HAS_NOT_ACCEPTED],
	[MonitorDetailsTypes.HAS_NOT_FINISHED, MonitorDetailsLabel.HAS_NOT_FINISHED],
	[MonitorDetailsTypes.IS_NOT_ON_ROUTE, MonitorDetailsLabel.IS_NOT_ON_ROUTE],
	[MonitorDetailsTypes.DELAYED, MonitorDetailsLabel.DELAYED],
	[MonitorDetailsTypes.NEW_MESSAGE, MonitorDetailsLabel.NEW_MESSAGE],
	[MonitorDetailsTypes.REVIEW, MonitorDetailsLabel.REVIEW],
	[MonitorDetailsTypes.ON_TIME, MonitorDetailsLabel.ON_TIME],
	[MonitorDetailsTypes.CHECK_IN, MonitorDetailsLabel.CHECK_IN],
	[MonitorDetailsTypes.QUALITAS_CO_CLOSURE_PENDING, MonitorDetailsLabel.QUALITAS_CO_CLOSURE_PENDING],
]);

export const ManageServicesOptions = {
	ManageActive: 'manage-active-services',
	AllServices: 'allServices',
	CheckInServices: 'check-in-services',
} as const;

export const QueuedServicesOptions = {
	AllServices: 'allServices',
	ManageServiceQ: 'manageServices',
	Urgent: 'urgentServices',
	Snooze: 'snoozeServices',
} as const;

export type TManageServicesOptions = (typeof ManageServicesOptions)[keyof typeof ManageServicesOptions];
export type TQueuedServicesOptions = (typeof QueuedServicesOptions)[keyof typeof QueuedServicesOptions];

/**
 * The dispatch status of a service is used to determine if the service is ready to be dispatched or if it needs to be managed.
 * This status is determined by the number of warnings it has in the `warnings` property.
 */
export const DispatchStatus = {
	/** This status is assigned when it has more than one warning */
	Manage: 'Manage',
	/** This status is assigned when it has no warnings. */
	Ready: 'Ready',
	/** This status is assigned when the service is scheduled with automatic routing */
	AutoProcessing: 'Auto Processing',
} as const;

export type TDispatchStatus = (typeof DispatchStatus)[keyof typeof DispatchStatus];

export const FilterByDispatchStatusOptions = [
	{
		value: DispatchStatus.Ready,
		label: 'Ready',
	},
	{
		value: DispatchStatus.Manage,
		label: 'Manage',
	},
	{
		value: DispatchStatus.AutoProcessing,
		label: 'Auto Processing',
	},
];

export const DivisionsDropdown = [
	{
		label: 'All Divisions',
		value: Division.All,
		all: true,
	},
	{
		icon: 'assets/images/icon-home.svg',
		label: 'Home',
		value: Division.Home,
	},
	{
		icon: 'assets/images/icon-road.svg',
		label: 'Road',
		value: Division.Road,
	},
	{
		icon: 'assets/images/icon-claims.svg',
		label: 'Claims',
		value: Division.Claims,
	},
	{
		icon: 'assets/images/icon-concierge.svg',
		label: 'Concierge',
		value: Division.Concierge,
	},
];

export const BranchesDropdown = [
	{
		icon: 'assets/images/flags/globe.png',
		label: 'Global',
		value: Branch.ALL,
		all: true,
	},
	{
		icon: 'assets/images/flags/round_flag_puerto_rico.png',
		label: 'Puerto Rico',
		value: Branch.PR,
	},
	{
		icon: 'assets/images/flags/round_flag_panama.png',
		label: 'Panama',
		value: Branch.PA,
	},
	{
		icon: 'assets/images/flags/costa_rica_flag.png',
		label: 'Costa Rica',
		value: Branch.CR,
	},
	{
		icon: 'assets/images/flags/round_flag_colombia.png',
		label: 'Colombia',
		value: Branch.CO,
	},
	{
		icon: 'assets/images/flags/round_flag_mexico.png',
		label: 'Mexico',
		value: Branch.MX,
	},
];
