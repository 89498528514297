import { NgClass, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
	selector: 'rb-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	standalone: true,
	imports: [NgClass, NgIf],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
	@Input() variant: 'primary' | 'secondary' | 'tertiary' = 'primary';
	@Input() theme: 'error' | 'success' | 'warning' | 'info' | 'orange' | 'blue' = 'orange';
	@Input() disable: boolean = false;
	@Input() size: 'sm' | 'md' | 'lg' = 'md';
	@Input() shape: 'square' | 'round' = 'round';
	@Input() hasShadow: boolean = false;
	@Input() hasTransparentBackground: boolean = false;

	@Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	public isIconOnlyButton: boolean;

	private selfElementRef = inject(ElementRef);

	@ContentChild(MatIcon, { static: true }) icon: MatIcon;

	ngOnInit(): void {
		const hasTextContent = this.selfElementRef.nativeElement.textContent.trim().length > 0;
		const hasIcon = this.icon !== undefined;
		this.isIconOnlyButton = !hasTextContent && hasIcon;
	}

	public onClick(): void {
		this.clickEvent.emit(true);
	}
}
