import { Division } from 'assets/constants/enums';

// eslint-disable-next-line
export const SITUATIONS = {
	LOCKSMITH: 'locksmith',
	FLAT_TIRE: 'flatTire',
	FUEL_DELIVERY: 'fuelDelivery',
	JUMP_START: 'jumpStart',
	BATTERY_DIAGNOSTIC: 'batteryDiagnostic',
	BATTERY_SALE: 'battery_sale',
	TOW_COLLISION: 'towCollision',
	TOW_COLLISSION: 'towCollission',
	TOW_BREAKDOWN: 'towBreakdown',
	TOW_TIRE: 'towTire',
	TOW_EXTRACTION: 'towExtraction',
	INSPECTIONS: 'inspections',
	OTHER_SERVICES: 'situationOtherServices',
	ELECTRICITY: 'electricity',
	PLUMBING: 'plumbing',
	HANDYMAN: 'handyman',
	WHEEL_LIFT: 'wheelLift',
	TOW_HEAVY_WEIGHT: 'towHeavyWeight',
	TAXI_TO_AIRPORT: 'taxi_to_airport',
	GLASSWORKS: 'glassworks',
	HOME_LOCKSMITH: 'home_locksmith',
	VIRTUAL_INSPECTION: 'virtual_inspection',
};

/**
 *  Maps the service type category to the icon name of that category.
 */
export function getServiceTypeCategoryToIcon(category: string, serviceTypeDivision: Division): string {
	switch (category) {
		case 'Electricity':
			return 'electricity';
		case 'Plumbing':
			return 'plumbing';
		case 'Home Locksmith':
			return 'home_locksmith';
		case 'Glassworks':
			return 'glassworks';
		case 'Handyman':
			return 'handyman';
		case 'Pest Control':
			return 'pest_control';
		case 'Fall Prevention':
			return 'fall_prevention';
		case 'Tech Assistance':
			return 'tech_assistance';
		case 'Gas':
			return 'gas';
		case 'Phone Tax Advisory':
			return 'phone_tax_advisory';
		case 'Phone Legal Guidance':
			return 'phone_legal_guidance';
		case 'Surveillance Expenses':
			return 'surveillance_expenses';
		case 'Tiles':
			return 'tiles';
		case 'Reference':
			return 'reference';
		case 'Finishes':
			return 'finishes';
		case 'Other Services':
			return 'other_services';
		case 'Pets':
			return 'pets';
		default:
			if (serviceTypeDivision === Division.Home) {
				return 'home';
			}
	}
}

export const OTHER_SERVICES_ALLOWED_PR = ['rx', 'glass_replacement', 'injury_or_sickness', 'luggage'];
