import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { convertToQueryParams } from '../../../../app/helpers/api-services.helper';
import { ResponseData } from '../../../../assets/models/ResponseData';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../auth';

export class ApiService {
	constructor(protected http: HttpClient, protected auth: AuthenticationService, private apiUrl: string) {}

	protected getRequestOptions(params?: Record<string, any>): { headers: HttpHeaders; params: HttpParams } {
		let headers = new HttpHeaders();
		let parameters = new HttpParams();

		const token: string = this.auth.getAuthToken() ? this.auth.getAuthToken() : 'Token';
		const userId = this.auth.getUserId();
		headers = headers.set('Content-Type', 'application/json').set('Authorization', token);
		if (userId) {
			headers = headers.set('User-Id', userId);
		}
		parameters = convertToQueryParams(params);

		return { headers, params: parameters };
	}

	protected get<T = ResponseData>(url: string, params: any = undefined) {
		return this.http.get<T>(`${this.apiUrl}/${url}`, this.getRequestOptions(params)).toPromise();
	}
	protected post(url: string, data: any): Promise<ResponseData> {
		return this.http.post<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions()).toPromise();
	}
	protected put(url: string, data: any): Promise<ResponseData> {
		return this.http.put<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions()).toPromise();
	}
	protected patch(url: string, data: any): Promise<ResponseData> {
		return this.http.patch<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions()).toPromise();
	}
	protected delete(url: string): Promise<ResponseData> {
		return this.http.delete<ResponseData>(`${this.apiUrl}/${url}`, this.getRequestOptions()).toPromise();
	}
	protected getAsObservable<T = ResponseData>(url: string, params: any = undefined): Observable<T> {
		return this.http.get<T>(`${this.apiUrl}/${url}`, this.getRequestOptions(params));
	}
	protected postAsObservable(url: string, data: any): Observable<ResponseData> {
		return this.http.post<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions());
	}
	protected putAsObservable(url: string, data: any): Observable<ResponseData> {
		return this.http.put<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions());
	}
	protected patchAsObservable(url: string, data: any): Observable<ResponseData> {
		return this.http.patch<ResponseData>(`${this.apiUrl}/${url}`, data, this.getRequestOptions());
	}
	protected deleteAsObservable(url: string): Observable<ResponseData> {
		return this.http.delete<ResponseData>(`${this.apiUrl}/${url}`, this.getRequestOptions());
	}
}

@Injectable({
	providedIn: 'root',
})
export class BaseService extends ApiService {
	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth, environment.apiLink);
	}
}
