import { Injectable } from '@angular/core';
import { NavigationEnd, Router, type Params as QueryParams } from '@angular/router';
import { DashboardSection } from './constants';
import { filter } from 'rxjs';
import { Division } from 'assets/constants/enums';
import { Branch } from 'assets/models/Branch';

/**
 * ServiceQNavigationService keeps track of the user's navigation history to the ServiceQ section
 * of the dashboard and manages the associated query parameters.
 */
@Injectable({
	providedIn: 'root',
})
export class ServiceQNavigationService {
	/**
	 * Stores the navigation history for the ServiceQ dashboard section, including the section name
	 * and any query parameters used during navigation.
	 */
	private dashboardHistory?: {
		dashboardSection: DashboardSection;
		queryParams: QueryParams;
	};

	/**
	 * Subscribes to router navigation events to monitor when the user navigates to the ServiceQ
	 * section of the dashboard. It captures and stores the query parameters for future use.
	 */
	constructor(private router: Router) {
		// Subscribe to router events, filtering for NavigationEnd events to capture completed navigations.
		this.router.events
			.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
			.subscribe((event) => {
				// The UrlTree represents the structure of the URL, including its segments and query parameters.
				const parsedUrl = this.router.parseUrl(event.urlAfterRedirects);

				// Access the 'primary' outlet's route segments from the parsed UrlTree.
				// In Angular, the 'primary' outlet is the default outlet for routing.
				// For the example URL:
				// http://localhost:4200/dashboard/service_q?branch=Puerto%20Rico&division=home
				// The parsedUrl.root.children['primary'] will contain the route segments for the primary outlet.
				const primaryChild = parsedUrl.root.children['primary'];

				if (!primaryChild) {
					// No primary route is present; exit early.
					return;
				}

				// Extract the array of URL segments from the primary route.
				// In our example, primaryChild.segments would be:
				// [
				//   { path: 'dashboard', parameters: {} },
				//   { path: 'service_q', parameters: {} }
				// ]
				const segments = primaryChild.segments;

				// Check if the URL matches the pattern '/dashboard/{section}'
				// Ensure there are at least two segments and the first is 'dashboard'.
				if (segments.length >= 2 && segments[0].path === 'dashboard') {
					const dashboardSection = segments[1].path as DashboardSection;

					if (dashboardSection === DashboardSection.ServiceQ) {
						// Store the dashboard section and query parameters when in 'ServiceQ'.
						this.dashboardHistory = {
							dashboardSection,
							queryParams: { ...parsedUrl.queryParams },
						};
					}
				}
			});
	}

	/**
	 * Retrieves the query parameters for the ServiceQ dashboard section by merging stored parameters
	 * with the provided base parameters. Ensures that 'branch' and 'division' are current.
	 */
	getServiceQParams(baseQueryParams: { division: Division; branch: Branch }): QueryParams {
		if (!this.dashboardHistory?.queryParams) {
			// No stored history is available; return the base query parameters.
			return { ...baseQueryParams };
		}

		const storedBranch = this.dashboardHistory.queryParams.branch;
		const storedDivision = this.dashboardHistory.queryParams.division;

		const hasDifferentBranch = storedBranch !== baseQueryParams.branch;
		const hasDifferentDivision = storedDivision !== baseQueryParams.division;

		// If either the branch or division has changed, return the base parameters.
		// This is because filters like driver, service type, provider, and account vary by branch and division.
		// For example, the account "Connect Home OnDemand PR" is exclusive to Puerto Rico.
		if (hasDifferentBranch || hasDifferentDivision) {
			return { ...baseQueryParams };
		}

		// Merge stored query parameters with base parameters,
		// ensuring 'branch' and 'division' are up-to-date.
		return {
			...this.dashboardHistory.queryParams,
			division: baseQueryParams.division,
			branch: baseQueryParams.branch,
		};
	}
}
