import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'rb-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss'],
	standalone: true,
	imports: [NgClass, NgIf],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
	@Input() variant: 'primary' | 'secondary' | 'disabled' = 'primary';
	@Input() size: 'xs' | 's' = 'xs';

	@Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() keyDownEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	public onClick(): void {
		this.clickEvent.emit(true);
	}

	public onKeyDown(): void {
		this.keyDownEvent.emit(true);
	}
}
