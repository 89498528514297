export const APPOINTMENT_TYPES = [
	{
		value: 'NOW',
	},
	{
		value: 'LATER',
	},
];

export const DRIVER_REASSIGNMENT_REASONS = [
	'Inconvenientes con equipo y/o vehiculo',
	'Servicio en ruta de otro Driver',
	'Driver no brinda ese tipo de servicio',
	'Driver no tiene equipo y/o vehiculo adecuado',
	'Driver no se encontraba en turno',
	'Driver seleccionado por error en despacho',
	'Proveedor solicita cambio de Driver',
	'Driver fuera de Zona',
	'Porque  el cliente solo solicitó información',
];

export const PANAMA_DID_NUMBER = [
	'307-2626',
	'378-2627',
	'378-2628',
	'378-2637',
	'378-2638',
	'378-2647',
	'378-2648',
	'Other',
];
export const COSTA_RICA_DID_NUMBER = [
	'2504-6000',
	'2504-6600',
	'2504-6517',
	'2504-6518',
	'2504-6519',
	'2504-6520',
	'2504-6521',
	'2504-6522',
	'Other',
];
export const PUERTO_RICO_DID_NUMBER = [
	'Connect App',
	'Connect Assistance',
	'787-919-7815',
	'787-200-3573',
	'787-200-3575',
	'Other',
];

export const CO_DID_NUMBER = ['+57 14432571', 'Other'];
export const DEFAULT_DID_NUMBER = PUERTO_RICO_DID_NUMBER;
export const DID_NUMBERS_BY_BRANCH = {
	Panama: PANAMA_DID_NUMBER,
	'Costa Rica': COSTA_RICA_DID_NUMBER,
	'Puerto Rico': PUERTO_RICO_DID_NUMBER,
	Colombia: CO_DID_NUMBER,
};

export const TYPE_OF_PROPERTY = ['Apartment', 'House'];
export const NUMBER_OF_ROOMS = ['0', '1', '2', '3', '4', '5 o más'];

export const CHANNEL_OPTIONS = {
	Panama: ['Call', 'Web App', 'WhatsApp'],
	alfred: ['Call', 'WhatsApp'],
};

export const PLATFORM = 'Helios Web';
