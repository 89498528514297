import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { AgmMarker } from '../../directives/marker';

import { GoogleMapsAPIWrapper } from '../google-maps-api-wrapper';
import { Marker } from '../google-maps-types';

declare var google: any;

@Injectable()
export class MarkerManager {
	protected _markers: Map<AgmMarker, Promise<Marker>> = new Map<AgmMarker, Promise<Marker>>();

	constructor(protected _mapsWrapper: GoogleMapsAPIWrapper, protected _zone: NgZone) {}

	async convertAnimation(uiAnim: 'BOUNCE' | 'DROP' | null): Promise<any> {
		if (uiAnim === null) {
			return null;
		} else {
			return this._mapsWrapper.getNativeMap().then(() => google.maps.Animation[uiAnim]);
		}
	}

	deleteMarker(marker: AgmMarker): Promise<void> {
		const m = this._markers.get(marker);
		if (m == null) {
			// marker already deleted
			return Promise.resolve();
		}
		return m.then((m: Marker) => {
			return this._zone.run(() => {
				m.setMap(null);
				this._markers.delete(marker);
			});
		});
	}

	updateMarkerPosition(marker: AgmMarker): Promise<void> {
		return this._markers
			.get(marker)
			.then((m: Marker) => m.setPosition({ lat: marker.latitude, lng: marker.longitude }));
	}

	updateTitle(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setTitle(marker.title));
	}

	updateLabel(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => {
			m.setLabel(marker.label);
		});
	}

	updateDraggable(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setDraggable(marker.draggable));
	}

	updateIcon(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setIcon(marker.iconUrl));
	}

	updateOpacity(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setOpacity(marker.opacity));
	}

	updateVisible(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setVisible(marker.visible));
	}

	updateZIndex(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setZIndex(marker.zIndex));
	}

	updateClickable(marker: AgmMarker): Promise<void> {
		return this._markers.get(marker).then((m: Marker) => m.setClickable(marker.clickable));
	}

	async updateAnimation(marker: AgmMarker) {
		const m = await this._markers.get(marker);
		m.setAnimation(await this.convertAnimation(marker.animation));
	}

	addMarker(marker: AgmMarker) {
		const markerPromise = new Promise<Marker>(async (resolve) =>
			this._mapsWrapper
				.createMarker({
					position: { lat: marker.latitude, lng: marker.longitude },
					label: marker.label,
					draggable: marker.draggable,
					icon: marker.iconUrl,
					opacity: marker.opacity,
					visible: marker.visible,
					zIndex: marker.zIndex,
					title: marker.title,
					clickable: marker.clickable,
					animation: await this.convertAnimation(marker.animation),
				})
				.then(resolve)
		);
		this._markers.set(marker, markerPromise);
	}

	getNativeMarker(marker: AgmMarker): Promise<Marker> {
		return this._markers.get(marker);
	}

	createEventObservable<T>(eventName: string, marker: AgmMarker): Observable<T> {
		return new Observable((observer: Observer<T>) => {
			this._markers.get(marker).then((m: Marker) => {
				m.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
			});
		});
	}
}
