// External imports
import { Component } from '@angular/core';

// Global imports

// Local imports
import { AuthenticationService } from '../../services/auth';
import { PendoService } from '../../services/pendo';
import { GlobalStateService } from '../../services/state/global-state';
import { HashUrlInterceptor } from 'modules/core/interceptors/HashUrlInterceptor';

@Component({
	selector: 'rb-root',
	template: `
		<rb-load-indicator [show]="isGlobalStateLoading">
			<rb-navbar />
			<router-outlet />
		</rb-load-indicator>
		<ibm-placeholder />
	`,
})
export class RootComponent {
	isGlobalStateLoading: boolean = false;

	constructor(
		authService: AuthenticationService,
		globalStateService: GlobalStateService,
		private pendoService: PendoService,
		private hashUrlService: HashUrlInterceptor
	) {
		authService.userLoggedInObservable.subscribe(async (userLoggedIn) => {
			if (!userLoggedIn) {
				globalStateService.clearState();
			} else {
				pendoService.initializePendo();
			}
		});
		globalStateService
			.getInitializingObservable()
			.subscribe((initializing) => (this.isGlobalStateLoading = initializing));
	}
}
