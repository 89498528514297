import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class HashUrlInterceptor {
	constructor(private router: Router) {
		this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: any) => {
			if (event.url.includes('#')) {
				const correctedUrl = event.url.replace('#', '');
				this.router.navigateByUrl(correctedUrl);
			}
		});
	}
}
