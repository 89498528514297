import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule as CarbonModalModule } from 'carbon-components-angular/modal';
import { ModalComponent } from './modal.component';

@NgModule({
	declarations: [ModalComponent],
	imports: [CommonModule, CarbonModalModule],
	exports: [ModalComponent],
})
export class ModalModule {}
