import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxModule, DropdownModule, ListItem } from 'carbon-components-angular';
import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'rb-combobox',
	templateUrl: './combobox.component.html',
	styleUrls: ['./combobox.component.scss'],
	standalone: true,
	imports: [ComboBoxModule, DropdownModule, FormsModule, CommonModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ComboboxComponent),
			multi: true,
		},
	],
})
export class ComboboxComponent implements OnInit, ControlValueAccessor, OnChanges {
	public _items: ListItem[] = [];
	@Input() invalidText: string;
	@Input() warnText: string;
	@Input() disabled: boolean;
	@Input() helperText: string;
	@Input() label: string;
	@Input() clearable: boolean = true;
	@Input() fieldValue: string = 'content';
	@Input() showIcon: boolean = false;
	/** This must be the last input to setup on the templates */
	@Input() set items(items: ListItem[]) {
		const newItems = cloneDeep(items);

		if (this.clearable && newItems[0]?.[this.fieldValue] !== this.label) {
			newItems.unshift({ content: this.label, selected: false, [this.fieldValue]: this.label, iconUrl: '' });
		}
		this._items = newItems;
	}
	@Input() type: 'multi' | 'single' = 'multi';
	@Input() initialValue: string | number | string[] | number[];
	@Output() setSelectedItem = new EventEmitter<ListItem | ListItem[]>();
	@Output() hasChanges = new EventEmitter<SimpleChanges>();

	public className: string = undefined;
	public selectedItem: ListItem | ListItem[] = undefined;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['initialValue']) {
			this.setInitialValue();
		}
		this.hasChanges.emit(changes);
	}

	ngOnInit() {
		this.setInitialValue();
	}

	setInitialValue() {
		if (!this.initialValue) {
			return;
		}

		const initialValueArray: any[] = Array.isArray(this.initialValue) ? this.initialValue : [this.initialValue];

		this._items.forEach((item) => {
			item.selected = initialValueArray.includes(item[this.fieldValue]);
		});

		if (this.type === 'single') {
			const selectedItemIndex = this._items.findIndex((item) => item.selected);
			this.selectedItem = selectedItemIndex !== -1 ? this._items[selectedItemIndex] : undefined;
			this.className = this.selectedItem ? 'filled' : undefined;
		} else {
			this.selectedItem = this._items.filter((item) => item.selected);
			this.className = this.selectedItem.length > 0 ? 'filled' : undefined;
		}
	}

	onSelectedItemClick(item: ListItem | ListItem[]) {
		if (this.type === 'multi') {
			//When its type=multi the item is an array of the object selected
			this.setSelectedItem.emit(item);
		} else {
			this.singleSelectedItem(item as ListItem);
		}
	}

	singleSelectedItem(item: ListItem) {
		if (this.clearable && item[this.fieldValue] === this.label) {
			this.selectedItem = undefined;
			this.className = undefined;
			this.setSelectedItem.emit(undefined);
		} else {
			this.className = 'filled';
			this.setSelectedItem.emit(item);
		}
	}

	search(event?: string) {
		if (event === '' || (event === undefined && !this.selectedItem)) {
			this.className = undefined;
		} else {
			this.className = 'filled';
		}
	}

	onDropdownClick() {
		this.className = 'filled';
	}

	onBlurHandler() {
		if (!this.selectedItem) {
			this.className = undefined;
		}
	}

	/**
	 * Writes a new value to the element.
	 * This method is part of the ControlValueAccessor interface to support Angular Forms.
	 * @param {any} obj - The new value for the component.
	 * @returns {void}
	 */
	writeValue(obj: any): void {
		// The functionality for handling the value is implemented in other methods.
	}

	/**
	 * Registers a function to be called when the component's value changes.
	 * This method is part of the ControlValueAccessor interface to support Angular Forms.
	 * @param {Function} fn - The function to be called on value changes.
	 * @returns {void}
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers a function to be called when the component is touched.
	 * This method is part of the ControlValueAccessor interface to support Angular Forms.
	 * @param {Function} fn - The function to be called on touch events.
	 * @returns {void}
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * The function to be called when the component's value changes.
	 * @type {(value: any) => void}
	 * @private
	 */
	private onChange: (value: any) => void = () => {};

	/**
	 * The function to be called when the component is touched.
	 * @type {() => void}
	 * @private
	 */
	private onTouched: () => void = () => {};

	resetCombobox() {
		this.selectedItem = undefined;
		this.className = undefined;
		this.setSelectedItem.emit(undefined);
	}
}
