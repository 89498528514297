import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CognitoService {
	private cognitoDomain = environment.COGNITO_DOMAIN;
	private clientId = environment.COGNITO_CLIENT_ID;
	private redirectUri: string;
	private logoutUri: string;
	constructor(private http: HttpClient) {
		const currentUrl = window.location.origin;
		this.redirectUri = `${currentUrl}/login/callback`;
		this.logoutUri = `${currentUrl}/login`;
	}

	getCognitoDomain(): string {
		return this.cognitoDomain;
	}

	getRedirectUri(): string {
		return this.redirectUri;
	}

	getClientId(): string {
		return this.clientId;
	}

	getLogoutUri(): string {
		return this.logoutUri;
	}

	getTokens(grantType: string, code: string, refresh_token: string): Observable<any> {
		const tokenUrl = `${this.cognitoDomain}/oauth2/token`;
		const body = new URLSearchParams({
			grant_type: grantType,
			client_id: this.clientId,
			redirect_uri: this.redirectUri,
		});

		if (code) {
			body.append('code', code);
		}
		if (refresh_token) {
			body.append('refresh_token', refresh_token);
		}

		return this.http.post<any>(tokenUrl, body.toString(), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	}

	getUserInfo(accessToken: string): Observable<any> {
		const userInfoUrl = `${this.cognitoDomain}/oauth2/userInfo`;
		const headers = new HttpHeaders({
			Authorization: `Bearer ${accessToken}`,
		});

		return this.http.get<any>(userInfoUrl, { headers });
	}

	revokeAccess(accessToken: string): Observable<any> {
		const logoutUrl = `${this.cognitoDomain}/oauth2/revoke`;
		const body = new URLSearchParams({
			client_id: this.clientId,
			token: accessToken,
		});

		return this.http.post(logoutUrl, body, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	}
}
