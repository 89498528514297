import { Division } from 'assets/constants/enums';
import { Branch } from 'assets/models/Branch';
import { Filter } from 'modules/management/constants';

export const DivisionsDropdownList: Filter[] = [
	{
		content: 'All',
		value: Division.All,
		selected: false,
		iconUrl: 'assets/images/design-system/map-pin.png',
	},
	{
		content: 'Claims',
		value: Division.Claims,
		selected: false,
		iconUrl: 'assets/images/design-system/claims-icon.png',
	},
	{
		content: 'Concierge',
		value: Division.Concierge,
		selected: false,
		iconUrl: 'assets/images/design-system/concierge-icon.png',
	},
	{
		content: 'Home',
		value: Division.Home,
		selected: false,
		iconUrl: 'assets/images/design-system/home-icon.png',
	},
	{
		content: 'Road',
		value: Division.Road,
		selected: false,
		iconUrl: 'assets/images/design-system/road-icon.png',
	},
];

export const BranchesDropdownList: Filter[] = [
	{
		content: 'Global',
		value: Branch.ALL,
		selected: false,
		iconUrl: 'assets/images/design-system/global-icon-circular.png',
	},
	{
		content: 'Puerto Rico',
		value: Branch.PR,
		selected: false,
		iconUrl: 'assets/images/design-system/puerto-rico-icon-circular.png',
	},
	{
		content: 'Costa Rica',
		value: Branch.CR,
		selected: false,
		iconUrl: 'assets/images/design-system/costa-rica-icon-circular.png',
	},
	{
		content: 'Colombia',
		value: Branch.CO,
		selected: false,
		iconUrl: 'assets/images/design-system/colombia-icon-circular.png',
	},
	{
		content: 'Panama',
		value: Branch.PA,
		selected: false,
		iconUrl: 'assets/images/design-system/panama-icon-circular.png',
	},
	{
		content: 'Mexico',
		value: Branch.MX,
		selected: false,
		iconUrl: 'assets/images/design-system/mexico-icon-circular.png',
	},
];
