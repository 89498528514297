import { Injectable } from '@angular/core';
import { EncryptedLocalStorageService } from '../storage';

declare const pendo;

@Injectable({
	providedIn: 'root',
})
export class PendoService {
	constructor(private storage: EncryptedLocalStorageService) {}

	initializePendo() {
		if (typeof pendo !== 'undefined') {
			const roles = this.storage.retrieve('roles');
			pendo.initialize({
				visitor: {
					id: this.storage.retrieve('_id'),
					email: this.storage.retrieve('email'),
					fullName: this.storage.retrieve('employeeName'),
					role: roles ? JSON.parse(roles) : [],
					defaultDivision: this.storage.retrieve('employeeDivision'),
					branch: this.storage.retrieve('employeeBranch'),
					userType: 'internal',
				},
			});
		} else {
			console.error('Pendo is not defined');
		}
	}
}
