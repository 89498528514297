import * as dayjs from 'dayjs';
import type { UnitType } from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { Branch } from 'assets/models/Branch';

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

type TimeFormat = 'HH:mm' | 'H:mm' | 'h:mm A' | 'hh:mm A';

export enum Timezones {
	'Puerto Rico' = 'America/Puerto_Rico',
	'Costa Rica' = 'America/Costa_Rica',
	'Panama' = 'America/Panama',
	'Colombia' = 'America/Bogota',
	'Mexico' = 'America/Mexico_City',
	'Global' = 'Etc/UTC',
}

export const addMinutes = (date: Date | string, minutes: number): Date => {
	return dayjs(date).add(minutes, 'minute').toDate();
};

export function isValidDate(date?: string | number | Date | null) {
	const dateObj = new Date(date as any);
	if (date && !Number.isNaN(dateObj.getTime())) {
		return true;
	}
	return false;
}

export function isSameDay(date, originalDate) {
	return dayjs(originalDate).isSame(date, 'day');
}

export const getDateWithoutTimeZone = (date: Date) => {
	return new Date(new Date(date).toISOString().slice(0, -1));
};

/**
 * Given a date, return the date at the start of the day
 * @param {Date} date - The date to get the start of the day for.
 * @returns A date object that represents the end of the day.
 */
export function getStartOfDay(date: Date) {
	return dayjs(date).startOf('day').toDate();
}

/**
 * Given a date, return the date at the end of the day
 * @param {Date} date - The date to get the end of the day for.
 * @returns A date object that represents the end of the day.
 */
export function getEndOfDay(date: Date) {
	return dayjs(date).endOf('day').toDate();
}

/**
 * Takes a string representing a date, and a format, and returns a Date object if the string date is
 * valid, otherwise returns undefined.
 *
 * @param {string} date - A string representing a date.
 * @param {string} stringDateFormat - The format of the date string.
 * @returns returns a date or undefined.
 */
export function parseCustomStringDate(date: string, stringDateFormat: string): Date | undefined {
	return dayjs(date, stringDateFormat, true).isValid() ? dayjs(date, stringDateFormat).toDate() : undefined;
}

/**
 * Given a date and a format string, return a formatted date string.
 *
 * @param {Date} date - The date to format.
 * @param {string} format - The format of the date.
 * @returns A string representing the date or undefined if either the date or the format was invalid.
 */
export function customFormatDate(date: Date | string | number, format: string): string | undefined {
	if (!date || !format) {
		return undefined;
	}
	return dayjs(date).format(format);
}

/**
 * Destructures a provided Date object into its date and time components.
 *
 * @param date - The Date object to destructure.
 * @param timeFormat - The desired format for the extracted time string.
 * @returns An object containing the separated date and time values.
 */
export function extractDateTimeComponents<T extends TimeFormat>(
	date: Date,
	timeFormat: T
): { dateComponent: Date; timeComponent: T } {
	const dateAsDayjs = dayjs(date);
	const dateOnlyString = dateAsDayjs.format('YYYY-MM-DD');
	const extractedTime = dateAsDayjs.format(timeFormat as string) as T;

	const dateWithoutTime = dayjs(dateOnlyString).startOf('day').toDate();

	return {
		dateComponent: dateWithoutTime,
		timeComponent: extractedTime,
	};
}

/**
 * Combines a provided Date object with a time string.
 * The date's existing time value will be stripped and replaced with the provided time string.
 *
 * @param date - The Date object whose year, month, and day values will be used.
 * @param time - The time string to be combined with the date. Should match the provided `timeFormat`.
 * @param timeFormat - The format of the provided time string.
 * @returns - The combined Date object, or null if there's an error in date-time combination.
 */
export function combineDateAndTime(date: Date, time: string, timeFormat: TimeFormat): Date | null {
	const combinedFormat = `YYYY-MM-DD ${timeFormat}`;
	const formattedDate = dayjs(date).format('YYYY-MM-DD');
	const formattedTimestamp = `${formattedDate} ${time}`;

	const parsedCombinedDate = dayjs(formattedTimestamp, combinedFormat);

	if (!parsedCombinedDate.isValid()) return null;

	return parsedCombinedDate.toDate();
}

export function combineDateAndTimeByTimeZone(
	date: Date,
	time: string,
	timeFormat: TimeFormat,
	branch: Branch
): Date | null {
	const timezoneBranch = Timezones[branch];
	const combinedFormat = `YYYY-MM-DD ${timeFormat}`;
	const formattedDate = dayjs(date).format('YYYY-MM-DD');
	const formattedTimestamp = `${formattedDate} ${time}`;

	// Parse the combined date and time
	const parsedCombinedDate = dayjs(formattedTimestamp, combinedFormat);

	if (!parsedCombinedDate.isValid()) return null;

	// Extract the year, month, and day from the parsed combined date
	const year = parsedCombinedDate.year();
	const month = parsedCombinedDate.month(); // 0-indexed
	const day = parsedCombinedDate.date();

	// Create a new date in the target timezone using the original day
	const newDateInTz = dayjs.tz(`${year}-${month + 1}-${day}`, timezoneBranch);

	// Set the correct time based on the parsed date
	const finalDate = newDateInTz
		.hour(parsedCombinedDate.hour())
		.minute(parsedCombinedDate.minute())
		.second(parsedCombinedDate.second())
		.millisecond(parsedCombinedDate.millisecond());

	// Convert to UTC directly, maintaining the original date in the process
	const utcDate = finalDate.utc();

	return utcDate.toDate(); // Convert to JavaScript Date
}

/**
 * It takes a number of minutes and returns a string of the format "Xhrs Ymins" or "Ymins" or "" if the
 * number of minutes is 0
 * @param duration - The duration of the activity in minutes.
 * @returns A string that is the duration in hours and minutes.
 */
export function humanizeDurationMinutes(duration) {
	if (!duration) return null;

	const hours = Math.trunc(duration / 60);
	const minutes = Math.trunc(duration % 60);

	let hourString;
	if (hours === 0) {
		hourString = '';
	} else {
		hourString = `${hours}hr `;
	}
	const minutesString = minutes > 0 ? `${minutes}min` : '';
	return `${hourString}${minutesString}`;
}

export const formatDate = (date: Date | string, format) => {
	return dayjs(date).format(format);
};

export const dateBetween = (date: Date | string, startDate: Date | string, finishDate: Date | string) => {
	return dayjs(date).isBetween(startDate, finishDate, 'second', '[]');
};

export const sameDate = (date: Date | string, dateToCompare: Date | string, unit: UnitType = 'milliseconds') => {
	return dayjs(date).isSame(dateToCompare, unit);
};

export const isLessThanLimitDays = (date: Date, limitDays: number) => {
	const dateLimit = dayjs().add(limitDays, 'days');

	const hours = dateLimit.diff(date, 'hours');
	const days = Math.floor(hours / 24);
	return days < limitDays;
};

export const subtractSeconds = (date: Date, seconds: number): Date => {
	return dayjs(date).subtract(seconds, 'second').toDate();
};

export const addDays = (date: Date | string, days: number): Date => {
	return dayjs(date).add(days, 'days').toDate();
};

export const isAfterDatesComparison = (date1: Date | string, date2: Date | string): boolean => {
	return dayjs(date1).isAfter(dayjs(date2));
};

/**
 * Converts 12-hour time format to 24-hour time format.
 * @param time - A string containing the time in 12-hour format including the time group (AM/PM).
 * @returns A string in 24-hour time format.
 */
export function convertTo24HourFormat(time: string): string {
	const timePattern12Hour = /^(0\d|1[0-2]):[0-5]\d\s*(AM|PM|am|pm)$/;
	const timePattern24Hour = /^([01]\d|2[0-3]):[0-5]\d$/;

	if (timePattern24Hour.test(time)) {
		return time;
	} else if (!timePattern12Hour.test(time)) {
		throw new Error(
			`Invalid time format: ${time}. Please provide a valid time in the format "hh:mm AM/PM" (12-hour format) or "HH:mm" (24-hour format).`
		);
	}

	const [timeHour, timeGroup] = time.split(/\s+/);
	const [hourString, minute] = timeHour.split(':');

	let hour = parseInt(hourString, 10);
	const normalizedTimeGroup = timeGroup.toUpperCase() as 'AM' | 'PM';

	if (normalizedTimeGroup === 'PM' && hour !== 12) {
		hour += 12;
	} else if (normalizedTimeGroup === 'AM' && hour === 12) {
		hour = 0;
	}

	const formattedHour = String(hour).padStart(2, '0');
	return `${formattedHour}:${minute}`;
}

/**
 * Converts 24-hour time format to 12-hour time format.
 * @param time - A string containing the time in 24-hour format.
 * @returns A string in 12-hour time format including the time group (AM/PM).
 */
export function convertTo12HourFormat(time: string): string {
	const timePattern12Hour = /^(0\d|1[0-2]):[0-5]\d\s*(AM|PM|am|pm)$/;
	const timePattern24Hour = /^([01]\d|2[0-3]):[0-5]\d$/;

	if (timePattern12Hour.test(time)) {
		return time;
	} else if (!timePattern24Hour.test(time)) {
		throw new Error(
			`Invalid time format: ${time}. Please provide a valid time in the format "HH:mm" (24-hour format) or "hh:mm AM/PM" (12-hour format).`
		);
	}

	const [hourString, minute] = time.split(':');

	const parsedHour = parseInt(hourString, 10);
	const timeGroup: 'AM' | 'PM' = parsedHour >= 12 ? 'PM' : 'AM';
	const hour = parsedHour % 12 === 0 ? 12 : parsedHour % 12;

	const formattedHour = String(hour).padStart(2, '0');
	return `${formattedHour}:${minute} ${timeGroup}`;
}
