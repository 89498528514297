<nav class="row no-gutters navbar" *ngIf="showNavbar">
	<div class="col-lg-2 col-md-2 col-sm-4 col-12 text-center">
		<img src="assets/images/helioslogo.png" alt="helios_logo" class="logo" />
	</div>
	<div class="col-lg-5 col-md-10 col-sm-6 col-12">
		<div class="autocomplete">
			<input
				type="text"
				placeholder="Search"
				aria-label="Global Search"
				(keyup)="onKeyUp($event)"
				(keydown)="clearDataResults()"
				[(ngModel)]="searchText"
				(keyup.enter)="search(searchText)"
			/>
			<button class="button-search">
				<i *ngIf="!searching; else loading" class="fa fa-search unclickable"></i>
				<ng-template #loading>
					<i class="fa fa-spinner fa-spin unclickable"></i>
				</ng-template>
			</button>
			<button class="button-close" *ngIf="searchText" (click)="clearInput()">
				<i class="fa fa-close"></i>
			</button>
		</div>
		<rb-services-result
			*ngIf="showContainer"
			[services]="results.services"
			[contacts]="results.contacts"
			(selectPONumber)="searchService($event)"
		/>
	</div>
	<div class="col-lg-5 col-md-12 col-sm-12 col-12 services">
		<div class="row no-gutters">
			<div class="notification-container col-12 col-lg-7 col-md-6 margin-auto">
				<rb-label variant="secondary" (click)="onNotificationClick()" (keyDown)="onNotificationClick()" tabindex="0"
					>{{ totalServicesCount }} S.W. Dispatch
					<span> ({{ selectedDivision }} - {{ selectedCountry }}) </span>
				</rb-label>
				<div class="settings" (keyDown)="openNotificationSettingsModal()" (click)="openNotificationSettingsModal()">
					<img
						src="./assets/images/design-system/settings-icon.png"
						alt="settings-img"
						attr.aria-label="Open Service Q Settings"
					/>
				</div>
			</div>

			<div class="col-12 col-lg-4 col-md-6">
				<div class="actions">
					<ng-container *ngFor="let action of actions">
						<img
							[src]="action.urlImage"
							alt="action-img"
							class="action"
							(click)="redirect(action)"
							[attr.aria-label]="action.ariaLabel"
						/>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</nav>

<rb-modal
	size="md"
	*ngIf="globalStateService.isInitialiazed()"
	(closeEmitter)="closeNotificationSettingsModal()"
	[open]="showNotificationSettings"
>
	<div class="modal-container">
		<div class="header">
			SERVICES WAITING DISPATCH
			<p>
				Please choose a division and/or a country to see the number of services awaiting dispatch in the header
				notification.
			</p>
		</div>
		<div class="input-card">
			<rb-combobox
				class="filters account-inputs"
				label="Divisions"
				selectLabel="Divisions"
				type="single"
				[items]="divisionsList"
				fieldValue="value"
				showIcon="true"
				[initialValue]="selectedDivision"
				(setSelectedItem)="onDivisionChange($event)"
			></rb-combobox>
			<rb-combobox
				class="filters account-inputs"
				label="Countries"
				selectLabel="Countries"
				type="single"
				[items]="branchesList"
				fieldValue="value"
				showIcon="true"
				[initialValue]="selectedCountry"
				(setSelectedItem)="onCountryChange($event)"
			></rb-combobox>
		</div>
		<div class="button-group">
			<rb-button
				variant="secondary"
				(click)="closeNotificationSettingsModal()"
				(keyDown)="closeNotificationSettingsModal()"
				tabindex="0"
				>Close</rb-button
			>
			<rb-button
				variant="primary"
				(click)="submitNotificationSettings()"
				(keyDown)="submitNotificationSettings()"
				tabindex="0"
				>Apply</rb-button
			>
		</div>
	</div>
</rb-modal>
