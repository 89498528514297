import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServicesResultComponent } from './components/services-result/services-result.component';
import { NavMenuComponent } from './nav-menu.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'modules/shared/design-system/modal/modal.module';
import { ButtonComponent } from 'modules/shared/design-system/button/button.component';
import { ComboboxComponent } from 'modules/shared/design-system/combobox/combobox.component';
import { LabelComponent } from 'modules/shared/design-system/label/label.component';
import { ServiceQNavigationService } from 'modules/dashboard/service-q-navigation.service';

export function childLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/theme/nav-menu/', '.json');
}
@NgModule({
	declarations: [NavMenuComponent, ServicesResultComponent],
	exports: [NavMenuComponent, ServicesResultComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ModalModule,
		ButtonComponent,
		ComboboxComponent,
		LabelComponent,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: childLoaderFactory,
				deps: [HttpClient],
			},
			isolate: true,
		}),
	],
	providers: [ServiceQNavigationService],
})
export class NavMenuModule {}
