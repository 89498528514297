// Global imports
import { Branch } from 'assets/models/Branch';
import { Division } from 'assets/constants/enums';

// Local imports
import { GlobalInitialState } from './types';

export const INITIAL_STATE: GlobalInitialState = {
	branch: Branch.ALL,
	serviceTypes: [],
	serviceTypesDict: new Map(),
	division: Division.All,
	accounts: [],
	accountsBySfId: {},
	auditReasons: [],
	providers: {
		[Branch.ALL]: [],
		[Branch.PR]: [],
		[Branch.PA]: [],
		[Branch.CR]: [],
		[Branch.CO]: [],
	},
	drivers: [],
	dispatchers: [],
	accountsPolicySymbols: {},
};
