<button
	class="button {{ variant }} {{ variant }}-{{ theme }} {{ variant }}-{{ shape }} {{ variant }}-{{ shape }}-{{ size }}"
	[ngClass]="{
		'icon-only-button-square': this.isIconOnlyButton && this.shape === 'square',
		'icon-only-button-round': this.isIconOnlyButton && this.shape === 'round',
		'has-shadow': this.hasShadow && this.shape === 'round',
		transparent: this.hasTransparentBackground && this.variant === 'secondary'
	}"
	type="button"
	[disabled]="disable"
	(click)="onClick()"
>
	<ng-content />
	<ng-content select="mat-icon" />
</button>
